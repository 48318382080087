var generic = generic || {};

Drupal.behaviors.switchLanguage = {
  attach: function() {
    var path = window.location.pathname;
    var pathArray = path.split('/');
    var pathPrefix = Drupal.settings.pathPrefix || pathArray[1] + '/';
    var currentLocale = generic.cookie('LOCALE');
    // Overrride when language url segment is present
    if (pathPrefix.match(/[a-z]{2}-e-[A-Z]{2}/)) {
      currentLocale = pathPrefix.replace('-e-', '_');
    }

    $('.js-switch-lang-link').on('click', function(event) {
      event.preventDefault();
      return switchLanguage($(this).text().slice(0, 2).toLowerCase(), currentLocale);
    });

    function switchLanguage(lang, currentLocale) {
      var postfixes = { en: '/', hi: '/hi-e-IN/' };
      var locale_cookie = lang + '_IN';
      // only switch if not already in requested language
      if (locale_cookie !== currentLocale) {
        var path = window.location.pathname.replace(/\/hi-e-IN/, '');
        document.cookie = 'LOCALE=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        generic.cookie('LOCALE', locale_cookie, { path: '/' });
        // Perlgem (.tmpl) files don't use language-country URL extension - only cookie
        if (path.indexOf('.tmpl') > 0) {
          postfixes[lang] = '';
        }
        // make use of Drupal settings to determine path
        if (
          Drupal.settings.elc_common !== undefined &&
          lang + '-e-IN_url' in Drupal.settings.elc_common &&
          path !== '/'
        ) {
          path = Drupal.settings.elc_common[lang + '-e-IN_url'];
        }

        if (path === '/') {
          path = '';
        }

        // recreate the URL given the new postfix
        var site_path = window.location.host + postfixes[lang] + path;
        site_path = site_path.replace(/\/+/g, '/');
        var url = window.location.protocol + '//' + site_path;
        var full_url = window.location.href;
        var full_url_parts;
        var appends = '';
        if (full_url.indexOf('#') > 0) {
          full_url_parts = full_url.split('#');
          appends = '#' + full_url_parts[1];
        } else if (full_url.indexOf('?') > 0) {
          full_url_parts = full_url.split('?');
          appends = '?' + full_url_parts[1];
        }
        window.location.href = url + appends;
      }
    }

    // if locale cookie is Hindi but we're on the default English site, reload with URL postfix
    if (
      currentLocale !== undefined && currentLocale.toLowerCase() === 'hi_in' &&
      pathPrefix.toLowerCase() !== 'hi-e-in/' &&
      path.indexOf('.tmpl') === -1
    ) {
      window.location.href =
        window.location.protocol +
        '//' +
        window.location.host +
        '/hi-e-IN' +
        window.location.pathname +
        window.location.search +
        window.location.hash;
    }

    // Update the 'active' style on the language toggle
    if (currentLocale !== undefined && currentLocale !== '') {
      $('.language-select__link').each(function() {
        var languageValue = $(this).text().slice(0, 2).toLowerCase();
        // Check the locale starts with this link's language
        if (currentLocale.indexOf(languageValue) === 0) {
          $(this).addClass('language-select__link--selected');
        } else {
          $(this).removeClass('language-select__link--selected');
        }
      });
    }
  },
};
